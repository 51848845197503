<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import { fv } from "../util/functions.js";
import moment from "moment";

export default {
  name: "EditClient",
  props: ["item"],
  data() {
    return {
      activeItem: Object.assign({}, this.item),
      rules: fv,
      userModel: {
        email: "",
        firstName: "",
        lastName: "",
        newPassword: "",
        role: "",
        telephoneNumber: "",
      },
      storeModel: {
        deliveryAddress: "",
        deliveryCity: "",
        deliveryComment: "",
        deliveryDistrict: "",
        deliveryFirstname: "",
        deliveryLastname: "",
        deliveryPhoneNumber: "",
        deliveryZipCode: 0,
        firmLocation: "",
        firmName: "",
        firmTaxNumber: "",
        storeAddress: "",
      },
      orders: [],
      displays: [],
    };
  },
  mounted() {
    this.getOrderList();
    this.getFridgeList();
  },
  computed: {
    fullName() {
      return `${this.activeItem.lastName} ${this.activeItem.firstName}`;
    },
    statusLabel() {
      return this.activeItem.confirmed ? "Aktív" : "Függőben";
    },
    transformedModel() {
      let final = {};
      let arr = Object.keys(this.userModel);
      arr.forEach((item) => {
        this.userModel[item] = this.activeItem[item];
      });
      return final;
    },
  },
  methods: {
    getOrderList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/orders?storeCode=${this.item.store.storeCode}`,
          data: null,
        })
        .then((res) => {
          this.orders = res;
        });
    },
    getFridgeList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/fridge-displays/stores/${this.item.store.storeCode}`,
          data: null,
        })
        .then((res) => {
          this.displays = res;
        });
    },
    formatDate(d) {
      return moment.unix(d).format("YYYY.MM.DD");
    },
    quit() {
      this.activeItem = null;
      eventBus.$emit("QUIT_EDIT");
    },
    save() {
      eventBus.$emit("SAVE_CLIENT", this.activeItem);
    },
    removeDisplay(display) {
      const ID = display.id;
      this.$store
        .dispatch("removeItem", {
          end: `admin/fridge-displays/${ID}`,
          data: null,
        })
        .then((res) => {});
    },
    activateUser() {
      this.$store
        .dispatch("putPath", {
          end: `admin/client-users/${this.activeItem.id}/confirm`,
          data: null,
        })
        .then((res) => {
          if (res) {
            messageBus.$emit("onNewMessage", {
              type: "success",
              label: this.$t("message.success"),
            });
          } else {
            this.activeItem.confirmed = false;
            messageBus.$emit("onNewMessage", {
              type: "error",
              label: this.$t("message.error"),
            });
          }
        });
    },
  },
};
</script>
<template>
  <v-container>
    <div class="bar">
      <div class="bar">
        <h3>
          <v-icon x-large color="secondary">mdi-store-outline</v-icon
          >{{ activeItem.store.storeCode }}
        </h3>
        <div class="spacer"></div>
        <h3>
          <v-icon x-large color="secondary">mdi-account</v-icon>{{ fullName }}
        </h3>
        <div class="spacer"></div>
        <div class="bar">
          <v-switch
            color="secondary"
            v-model="activeItem.confirmed"
            @change="activateUser"
            :disabled="activeItem.confirmed"
          ></v-switch>
          <p>Státusz {{ statusLabel }}</p>
        </div>
        <!--         <v-switch
          v-model="local.isPending"
          :label="$t('app.isClientPending')"
        ></v-switch> -->
      </div>
      <div class="export">
        <v-btn color="secondary" x-large @click="save()">
          {{ $t("app.save") }}
        </v-btn>
        <v-btn x-large @click="quit()">
          {{ $t("app.cancel") }}
        </v-btn>
      </div>
    </div>
    <div class="f">
      <!-- EMAIL -->
      <v-card>
        <v-card-title>
          {{ $t("form.email") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('form.email')"
            placeholder="*****@hellenergy.hu
"
            outlined
            :rules="rules.email"
            v-model="activeItem.email"
          ></v-text-field>
          <h3>Pontok</h3>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Totál:</v-list-item-subtitle>
                <v-list-item-title
                  ><span class="bigDigit">
                    {{ activeItem.store.allCollectedPoints }}
                  </span></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Aktív:</v-list-item-subtitle>
                <v-list-item-title>
                  <span class="bigDigit">{{
                    activeItem.store.currentPoints
                  }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle
                  >{{ $t("agent.role") }}:</v-list-item-subtitle
                >
                <v-list-item-title>
                  <h3>{{ $t(activeItem.role) }}</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <!-- PERSONAL -->
      <v-card>
        <v-card-title>
          {{ $t("form.personal") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('agent.firstName')"
            placeholder=""
            outlined
            v-model="activeItem.firstName"
          ></v-text-field>
          <v-text-field
            :label="$t('agent.lastName')"
            placeholder=""
            outlined
            v-model="activeItem.lastName"
          ></v-text-field>
          <v-text-field
            :label="$t('agent.phone')"
            placeholder=""
            outlined
            v-model="activeItem.telephoneNumber"
          ></v-text-field>
        </v-card-text>
      </v-card>

      <!-- COMPANY -->
      <v-card>
        <v-card-title> Céges </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('agent.companyName')"
            placeholder=""
            outlined
            v-model="activeItem.store.firmName"
          ></v-text-field>
          <v-text-field
            label="Székhely"
            placeholder=""
            outlined
            v-model="activeItem.store.firmLocation"
          ></v-text-field>
          <v-text-field
            :label="$t('agent.taxNumber')"
            placeholder=""
            outlined
            v-model="activeItem.store.firmTaxNumber"
          ></v-text-field>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>
          {{ $t("agent.deliveryAddress") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('agent.firstName')"
            placeholder=""
            outlined
            v-model="activeItem.firstName"
          ></v-text-field>
          <v-text-field
            :label="$t('agent.lastName')"
            placeholder=""
            outlined
            v-model="activeItem.lastName"
          ></v-text-field>
          <v-text-field
            :label="$t('agent.deliveryAddress')"
            placeholder=""
            outlined
            v-model="activeItem.store.deliveryAddress"
          ></v-text-field>
          <v-text-field
            :label="$t('form.city')"
            placeholder=""
            outlined
            v-model="activeItem.store.deliveryCity"
          ></v-text-field>
          <v-text-field
            :label="$t('form.district')"
            placeholder=""
            outlined
            v-model="activeItem.store.deliveryDistrict"
          ></v-text-field>
          <v-text-field
            :label="$t('store.zipCode')"
            placeholder=""
            outlined
            v-model="activeItem.store.deliveryCity"
          ></v-text-field>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Kihelyezések, hűtők listája </v-card-title>
        <v-card-text>
          <v-card
            tile
            class="order"
            v-for="(display, indx) in displays"
            :key="indx"
          >
            <v-card-text class="d-flex justify-space-between">
              <div>
                {{ display.fridgeDisplayType.name }} <br />
                <v-icon>mdi-qrcode</v-icon><code>{{ display.qrCode }}</code>
              </div>
              <div>
                <v-btn outlined icon @click="removeDisplay(display)"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title> Beváltott termékek </v-card-title>
        <v-card-text
          v-if="orders.length > 0"
          style="overflow-y: scroll; height: 300px !important"
        >
          <v-card
            tile
            class="order"
            v-for="(order, indx) in orders"
            :key="indx"
          >
            <v-card-text
              ><h4>{{ order.product.name }}</h4>
              <span> {{ order.product.price }} pont</span><br />
              <span> Mennyiség: {{ order.quantity }} pont</span><br />
              <span>
                Vásárlás ideje: {{ formatDate(order.orderedAt) }}
              </span></v-card-text
            >
          </v-card>
        </v-card-text>
        <v-card-text v-else> Nem rendelt még. </v-card-text>
      </v-card>
    </div>
    <!--      "deliveryAddress": "string",
    "deliveryCity": "string",
    "deliveryComment": "string",
    "deliveryDistrict": "string",
    "deliveryFirstname": "string",
    "deliveryLastname": "string",
    "deliveryPhoneNumber": "string",
    "deliveryZipCode": 0,
    "firmLocation": "string",
    "firmName": "string",
    "firmTaxNumber": "string",
    "storeAddress": "string" -->
  </v-container>
</template>

<style scoped>
.f {
  display: flex;
  flex-wrap: wrap;
  background-color: #f2f2f2;
  margin: 2rem 0;
}
.f > * {
  flex: 1 1 45%;
  margin: 1rem;
}
.f > *:last-child {
  /*margin-right: 0;*/
}
.bar p {
  margin: 0;
}
.bar h3 {
  font-weight: 400;
}
</style>
