<template>
  <div class="Clients content">
    <v-container v-if="!isEdit">
      <v-card>
        <v-card-text>
          <div class="bar">
            <div class="bar">
              <TextSearch></TextSearch>
              <div class="spacer"></div>
              <v-switch
                v-model="local.isPending"
                :label="$t('app.isClientPending')"
              ></v-switch>
            </div>
            <div class="export">
              <v-btn
                :disabled="toExport.length < 1"
                color="secondary"
                x-large
                @click="exportList()"
              >
                {{ $t("app.export") }}
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container max-width="100%" v-if="!isEdit">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="table"
        v-model="selected"
        class="elevation-1"
      >
        <template v-slot:item="row">
          <tr>
            <td class="text-xs-right">
              <v-simple-checkbox
                v-model="row.item.selected"
                :ripple="false"
                @click="updateSelected()"
              ></v-simple-checkbox>
            </td>
            <td class="text-xs-right">{{ row.item.storeCode }}</td>
            <td class="text-xs-right">{{ row.item.name }}</td>
            <td class="text-xs-right">{{ row.item.email }}</td>
            <!-- <td class="text-xs-right">{{ row.item.telephoneNumber }}</td> -->
            <td class="text-xs-right">{{ row.item.allCollectedPoints }}</td>
            <td class="text-xs-right">{{ row.item.currentPoints }}</td>
            <td class="text-xs-right">{{ row.item.createdAt }}</td>
            <td class="text-xs-right">{{ row.item.confirmed }}</td>
            <td class="text-xs-right">
              <v-btn icon @click="deleteItem(row.item)" elevation="1">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn icon @click="editItem(row.item)" elevation="1">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("app.noData") }}</p>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.deleteWarning") }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="deleteNotice"
            outlined
            label="Törlés oka (opcionális)"
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn @click="finalize()" color="red lighten-1">
            {{ $t("app.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <EditClient
      v-if="activeItem && isEdit"
      :item="activeItemOriginal"
    ></EditClient>
  </div>
</template>

<script>
import moment from "moment";
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import TextSearch from "../components/TextSearch.vue";
import EditClient from "../components/EditClient.vue";
import { getLoggedInHeader } from "../util/functions";
import { API_BASE } from "../util/constants";
export default {
  name: "ClientsComponent",
  components: {
    TextSearch,
    EditClient,
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("app.export"),
          sortable: false,
        },
        {
          text: this.$t("store.storeCode"),
          sortable: false,
        },
        {
          text: this.$t("agent.name"),
          sortable: false,
        },
        {
          text: this.$t("form.email"),
          sortable: false,
        },
        // {
        //   text: this.$t("agent.phone"),
        //   sortable: false,
        // },
        {
          text: this.$t("store.allCollectedPoints"),
          sortable: false,
        },
        {
          text: this.$t("store.currentPoints"),
          sortable: false,
        },
        {
          text: this.$t("agent.createdOn"),
          sortable: false,
        },
        {
          text: this.$t("agent.status"),
          sortable: false,
        },
        {
          text: this.$t("app.actions"),
          sortable: false,
        },
      ],
      data: [],
      selected: [],
      activeItem: null,
      activeItems: [],
      dialogs: [],
      deleteNotice: null,
      dialog: false,
      isEdit: false,
      currentPage: 1,
      toExport: [],
      local: {
        isPending: false,
      },
      filter: {
        keyword: "Keres",
        pending: true,
      },
    };
  },
  created() {
    eventBus.$on("DO_SEARCH", (data) => {
      this.doSearch(data);
    });
    eventBus.$on("QUIT_EDIT", (data) => {
      this.activeItem = null;
      this.isEdit = false;
    });

    eventBus.$on("SAVE_CLIENT", (data) => {
      this.saveItem(data);
    });
  },
  mounted() {
    this.getList();
  },
  computed: {
    table() {
      let a = [];
      this.data.forEach((item) => {
        let obj = {};
        obj.storeCode = item.store.storeCode;
        obj.name = `${item.lastName} ${item.firstName}`;
        obj.id = item.id;
        obj.email = item.email;
        obj.currentPoints = item.store.currentPoints;
        obj.telephoneNumber = item.telephoneNumber;
        obj.allCollectedPoints = item.store.allCollectedPoints;
        obj.confirmed = item.confirmed ? "Aktív" : "Függőben";
        obj.createdAt = moment.unix(item.createdAt).format("YYYY.MM.DD");
        obj.selected = false;
        a.push(obj);
      });
      return a;
    },
    activeItemOriginal() {
      if (!this.activeItem) return null;
      return this.data.find((item) => item.id == this.activeItem.id);
    },
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/client-users`,
          data: null,
        })
        .then((res) => {
          this.data = res;
        });
    },
    updateSelected() {
      let selected = this.table.filter((item) => item.selected);
      let selectedData = [];
      selected.forEach((item) => {
        this.data.forEach((data) => {
          if (data.email === item.email) {
            let model = {
              allCollectedPoints: data.store.allCollectedPoints,
              createdAt: data.createdAt,
              currentPoints: data.store.currentPoints,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              numberOfDaysAfterLastUpload:
                data.store.numberOfDaysAfterLastUpload || 0,
              status: data.confirmed ? "Aktív" : "Függőben",
              storeCode: data.store.storeCode,
              telephoneNumber: data.telephoneNumber,
            };

            selectedData.push(model);
          }
        });
      });
      this.toExport = selectedData;
    },
    async doSearch(data) {
      if (data.length > 1) {
        this.filter.keyword = data;
      } else {
        this.filter.keyword = "";
      }
      this.filter.pending = this.local.isPending;
      var myHeaders = getLoggedInHeader(localStorage.getItem("token"));
      var raw = JSON.stringify(this.filter);
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      let res = await fetch(
        `${API_BASE}admin/client-users/filtered`,
        requestOptions
      );
      if (res.ok) {
        const jsonResponse = await res.json();
        if (jsonResponse.length > 0) {
          this.data = jsonResponse;
        } else {
          messageBus.$emit("onNewMessage", {
            type: "error",
            label: this.$t("message.noResult"),
          });
        }
      } else {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: this.$t("message.error"),
        });
      }
    },
    async exportList() {
      var myHeaders = getLoggedInHeader(localStorage.getItem("token"));
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        redirect: "follow",
        body: JSON.stringify(this.toExport),
      };
      let res = await fetch(
        `${API_BASE}admin/client-users/export`,
        requestOptions
      );

      if (res.ok) {
        let blob = await res.blob();
        // res.headers.forEach(console.log);
        let fname = "";
        for (let entry of res.headers.entries()) {
          if (entry[0] === "filename") {
            fname = entry[1];
          }
        }
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fname.length > 0 ? fname : "eport.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      }
    },
    handlePagination() {
      this.getList();
    },
    deleteItem(item) {
      this.activeItem = item;
      this.dialog = true;
    },
    editItem(item) {
      this.isEdit = true;
      this.activeItem = item;
    },
    finalize() {
      const m = this.deleteNotice.length > 0 ? this.deleteNotice : "";
      this.$store
        .dispatch("removeItem", {
          end: `admin/client-users/${this.activeItem.id}?message=${encodeURI(
            m
          )}`,
          data: null,
        })
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.deleteNotice = null;
          this.activeItem = null;
          this.dialog = false;
        });
    },
    saveItem(item) {
      const requestOptions = {
        method: "PUT",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(item),
        redirect: "follow",
      };

      fetch(`${API_BASE}admin/client-users/${item.id}`, requestOptions)
        .then((response) => {
          // console.log(response);
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
        })
        .then((result) => {})
        .catch((error) => console.log("error", error));
    },
  },
};
</script>
